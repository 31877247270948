<template>

    <div>

        <carousel ref="carousel" @page-change="pageChange" :paginationEnabled="false" :perPage="1" :navigationEnabled="false" :autoplay="true" :autoplayHoverPause="true" :loop="true" :autoplayTimeout="10000" :speed="100">

            <template v-if="slidesloaded">


            <!-- are there any slides at all -->
            <template v-if="slidesdata.length==0">
                    <div class="grid-x">
                        <h5 class="cell text-center">Welcome to Oddsgalore</h5>
                        <div class="cell">We compare and analyse bookmaker and exchange odds</div>
                        <div class="cell">You can personalise what odds you see and how you see them</div>
                        <div class="cell">We bring you all the latest hints, odds spots and gossip</div>
                        <div class="cell">And the best new and existing customer bookmakerpromotions</div>
                    </div>
            </template>


            <!-- This uses same feed as main slider-->
            <template v-if="slidesdata.length>0" style="font-weight: bold">

              <template v-for="(row0,i0) in slidesdata" >

                    <template v-if="row0.slide[0].slidertype=='P'"> 

                        <!-- split p slider into 3 slides -->
                    
                                   <template v-for="(row,i) in row0.slide[0].promos" >
 
                                            <slide :key="i">

                                                <div class="cell small-4" style="font-size:x-small; color:black; font-weight: bold; height:62px " :key=i >

                                                    <singleadverts :row="row" :backcolour="row.BackColour" format="smallpromo"></singleadverts>

                                                </div>

                                            </slide> 

                                        </template>
                    

                    </template> 
                    -<template v-if="row0.slide[0].slidertype=='I'">
                        
                         <slide  :key="(i0+99)">
                          
                            <div @click="HTMLClicked" style="color:black;height:62px;"  v-html="getStaticSlide(row0.slide[0].filedatalocation)"></div>

                        </slide>
                    
                    </template> 

                </template>

            </template>

            </template>


        </carousel>


        </div>


</template>

<script>


   import { Carousel, Slide } from 'vue-carousel';
   import singleadverts from '/src/components/adverts/singleadverts.vue'

   export default {
        name: "adcarouselnewads",
        components: {
            Carousel,
            Slide,
            singleadverts
        },
        data() {
            return {
                images: [],
                slides: null,
                slidesdata: null,
                slidesloaded: false,
                url: ['/Odds-Analysis','/graph','/Horses-Mouth'],
                title: ['We Analyse the Odds', 'We show our Bets','The Horse smouth'],
                featurecounter: 0,
                intervalID: undefined


            }
        },
        computed: {
            currentIndex: {
                cache: false,
                get() {
                    return this.$refs.carousel ? this.$refs.carousel.currentPage : 0;
                }
            }
        },
        mounted() {
          
           this.pageChange(0); 
            
        },
        created() {
           
            this.getSlideData();
        }, 

        methods: {

            HTMLClicked: function (val) {
                //before moving off cancel timer
            
                clearInterval(this.intervalID)
                this.intervalID == undefined;
                this.$router.push(this.url[this.featurecounter-1])
            },


           pageChange: function (page) {

                
                //turn interval on and off if not page 0
                if (page != 0) {
                    //run updateScreen Manually for the first time
                    clearInterval(this.intervalID)
                    this.intervalID == undefined;
                    
                } else {
                    
                    this.intervalID = setInterval(this.updateScreen,2000);  
                }

            },
   
            updateScreen: function() {
                //reset counter if 3
                try {
                if (this.featurecounter == 3) this.featurecounter = 0;
                    document.getElementById('title').innerHTML = this.langConv(this.title[this.featurecounter]);
                this.featurecounter++
                } catch {
                     //run updateScreen Manually for the first time
                     clearInterval(this.intervalID)
                    this.intervalID == undefined;
                }
            },

            getArrayValue: function (arr,value){
                        return arr[0][value]
            },
            imageURL: function (val1, val2) {
                    return val1 + val2
            },


            goToSlide: function (slideNo) {
                this.$refs.carousel.goToPage(slideNo);
            },

            currentSlide: function (slideNo) {

                var bn = false;

                if (slideNo == this.currentIndex) {
                    bn = true;
                }

                return bn;

            },
            getStaticSlide: function (filename) {
               try {

                return require("/public/assets/slides/small" + filename);

               } catch (e) {
                    console.log(e)
                }
            },
            //get data for banners
            getSlideData: function () {

                try {

                //does the country have a language version of the slider 21/12/2023 - Test for Germany only
                var country =  this.$store.state.countryname;

                country = this.backtoEnglish(country);

                this.localJSONLoad(process.env.VUE_APP_DATA + "slider " + country + ".json").then(response => {
                    this.slidesdata = response
                    this.slidesLoaded()
               }).catch(error => {
                   
                   console.log(error)
               });

            } catch (e) {
                console.log(e)
            }

            },

            slidesLoaded: function () {
                this.slidesloaded = true;
                this.$emit("slides-loaded");
            }
        }

    }
</script>

<style scoped>

@import "../../style/bkmsmall.css";

   .VueCarousel-slide {
        position: relative;
        display: inline-block; /* Make the width of box same as image */
        color: #fff;
        height: 62px;
    }

    #bg {
        width: 100% !important;
        height: 100% !important;
        background-size: cover !important;
    }

    .slidertext {
        background-color: #0d0b38  ;
        color: white;
        padding:3px;
        font-size: small;
        font-weight: bold;
        border-radius: 10px;
    }

        .slidertextleft {
        font-family:"roboto condensed";
        color: white;
        padding:3px;
        font-size: 13px;
        font-weight:400;
        border-radius: 10px 0px 0px 10px;

    }

    .slidertextright {
      
        color: white;
        padding:3px;
        font-weight: 500;
        font-size: small;
        border-radius: 0px 10px 10px 0px;
    }


    #basicslider {
        
      font-size: 12px;
      
        background-color: rgba(202, 198, 198, 0.4);
        color: #0d0b38;
       
        padding: 6px;

    }

    .currentslider {
       
        border-bottom: 3px solid gold;
        border-radius: 0px 0px 5px 5px;
        border-right: white solid 1px;
        cursor: pointer;
    }

    .noncurrentslider {
     
        /* border-top: 2px solid grey; */
        border-radius: 0px 0px 5px 5px;
        border-right: white solid 1px;
        cursor: pointer;
    }



.VueCarousel-navigation-button {
    /* background-color:#eee !important ; */
    /* padding: 3px !important; */
    /* border-radius: 50%; */
    /* color: darkslategray !important; */
    /* border: 1px solid darkslategrey !important; */
   

    font-size: x-small !important;
}

    .slideheader {position:absolute; left:40px;top: 10px; background-color:#ffcd03;border-radius:20px 20px 20px 20px; padding:4px 10px 4px 15px;font-weight:500; font-size:13px;}

</style>
